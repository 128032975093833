<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider
                        name="name"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('name')">
                            <b-form-input size="sm" v-model="formData.name">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>

                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider
                        name="name_en"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('name_en')">
                            <b-form-input size="sm" v-model="formData.name_en"></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>

                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

// Services

// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import ProgramOutcomeDefinition from "@/services/ProgramOutcomeDefinition";

export default {
    components: {
        ParameterSelectbox,
        ProgramSelectbox,
        StaffAutoComplete,
        StatusSelectbox,
        MultiSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formData: {
                name:null,
                name_en:null,

            },
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            ProgramOutcomeDefinition.show(id)
                .then(response => {
                    let data = response.data.data;
                        this.formData = {
                            name: data.name,
                            name_en: data.name_en,
                        }

                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
        async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    ProgramOutcomeDefinition.update(this.formId, this.formData)
                        .then(response => {
                            this.$emit('updateFormSuccess', true);
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.formLoading = false
                        })
                }
        }
    }
}
</script>
