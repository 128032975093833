<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider
                        name="name"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('name')">
                            <b-form-input size="sm" v-model="formData.name">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>

                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider
                        name="name_en"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('name_en')">
                            <b-form-input size="sm" v-model="formData.name_en"></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>

                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="storeForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import ProgramOutcomeDefinition from "@/services/ProgramOutcomeDefinition";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            formData: {
                course_id:null,
                name:null,
                name_en:null,
                rank:null,
                status:null,
                tyyc_basic_field_type:null,
                parent_id:null
            },
        }
    },
    methods: {
        async storeForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    ProgramOutcomeDefinition.store(this.formData)
                        .then(response => {
                            this.$emit('createFormSuccess', true);
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.formLoading = false
                        })
                }
        }
    }
}
</script>
